<template>
  <div id="order-models">
    <!-- Content -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Modelos - Ordernar</h4>
          </CCardHeader>

          <CCardBody>
            <!-- Sortable -->
            <CRow>
              <CCol col="12">
                <Sortable
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-switch-status="false"
                  :enable-export="false"
                  @endDragging="endDragging"
                >
                </Sortable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Sortable from '@/components/ui/Sortable'
import { orderBy, debounce } from 'lodash'
import ModelService from '@/services/models.service'

export default {
  metaInfo: {
    title: 'Modelos',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_models')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    Sortable
  },

  data() {
    return {
      datatable: {
        items: [],
        fields: [
          {
            key: 'status',
            label: 'Status',
            _style: 'width: 5rem;',
            sorter: false
          },
          {
            key: 'title',
            label: 'Título',
            sorter: false
          }
        ]
      },
      query: {
        search: '',
        page: 1,
        perpage: localStorage.getItem('perPageItem')
          ? parseFloat(localStorage.getItem('perPageItem'))
          : 10,
        pagination: false,
        status: true,
        sorter: {
          asc: true,
          column: 'order'
        }
      },
      processing: false
    }
  },

  async created() {
    await this.updateDataTable()
  },

  methods: {
    async updateDataTable() {
      const response = await ModelService.index(this.query)

      if (response.data) {
        const newItems = response.data.map((item) => ({ ...item }))

        this.datatable.items = orderBy(newItems, ['order'])
      }
    },

    endDragging: debounce(function (items) {
      this.datatable.items = items
      ModelService.updateOrders(this.datatable.items)

      this.$notify({
        group: 'notifications',
        type: 'success',
        text: 'Ordenação alterada com sucesso.'
      })
    }, 1000)
  }
}
</script>

<style lang="scss">
#order-models {
  .orderButton {
    margin-right: 10px;
  }

  .thumb {
    width: 80px !important;
  }
}
</style>
